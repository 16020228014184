import React, { useState } from "react";
import Logo from "../assets/logo.svg";

const Navbar = () => {
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 200) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <nav
      className={
        navbar
          ? "flex z-10 fixed w-full items-center justify-between p-4 md:p-8 ease-in-out duration-1000 bg-black"
          : "flex z-10 fixed w-full items-center justify-between p-4 md:p-12 bg-gradient-to-b from-[#ff41372f] via-transparent  to-transparent"
      }
    >
      <img src={Logo} alt='Logo' className='w-24 md:w-[150px]' />
      <a
        href='mailto: team@nervelabsafrica.com'
        className='hidden md:block bg-[#FF5637] text-white px-6 py-3 rounded-xl hover:bg-[#c53218]'
      >
        Contact us
      </a>
    </nav>
  );
};

export default Navbar;
