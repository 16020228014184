import React from "react";
import { Navbar } from "../components";
import Hero from "../assets/top.svg";
import Hdit from "../assets/hdit.png";
import {
  Location,
  Email,
  Phone,
  AAJ,
  Cheqloca,
  Quess,
  Logo,
  Fav,
} from "../assets";
import "../assets/float.css";
import "react-slideshow-image/dist/styles.css";
import "animate.css";
import { Slide } from "react-slideshow-image";
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";

const Home = () => {
  return (
    <div className='bg-[#0c1118] h-[100vh]'>
      <Navbar />
      <div className='h-screen md:px-24 md:pt:20 flex flex-col text-center items-center justify-around w-full md:flex-row md:text-left'>
        <div className='mt-12 w-full md:mt-0'>
          <p className='text-xl md:text-3xl mb-3 text-slate-400'>
            Let's help you
          </p>
          <h1 className='text-4xl font-bold text-white md:text-6xl break-normal'>
            Create your own unique <br />{" "}
            <span className='text-[#FF5637]'></span>{" "}
          </h1>
          <div className='mt-10 md:mt-20 flex flex-col justify-center gap-4 md:flex-row items-center md:justify-start'>
            <a
              href='mailto: team@nervelabsafrica.com'
              className='bg-[#FF5637] hover:bg-[#c53218] md:text-2xl md:font-bold md:mr-4 text-white px-6 py-3 md:px-12 md:py-6 rounded-xl'
            >
              Talk to us
            </a>
            {/* <a
              href='/'
              className='border-[#FF5637] hover:bg-[#ff5637] md:text-2xl md:font-bold border-solid border-2 text-white px-6 py-3  md:px-12 md:py-6 rounded-xl'
            >
              How we work
            </a> */}
          </div>
        </div>
        <div className='w-full flex item-center md:justify-end justify-center'>
          <img src={Hero} alt='hero' className='w-[260px] md:w-full laptop ' />
        </div>
      </div>
      <div className='bg-black text-white h-auto py-32 px-6 md:px-12'>
        <h1 className='font-bold md:text-6xl text-4xl text-center md:text-left mb-10 md:mb-24'>
          What we do
        </h1>
        <div className='flex gap-5 flex-wrap md:flex-nowrap'>
          <div className='flex p-10 flex-col items-start justify-between border-[#FF5637] border-solid border-2 rounded-lg hover:bg-[#FF5636] ease-in duration-700 cursor-pointer'>
            <h2 className='font-bold text-2xl mb-20'>01</h2>
            <div>
              <h2 className='font-bold text-3xl mb-8'>
                Research and Software Development
              </h2>
              <p>
                <p className='font-bold'>
                  "There's a way to do it better. Find it" - Thomas Edison
                </p>
                This quote by Thomas Edison is what we strive to do at
                Nervelabs. We research and find innovative ways for businesses
                to thrive with the use of technology by building softwares. We
                do not just look out for businesses but also for everyday normal
                people by building innovative solutions solving real world
                problems
              </p>
            </div>
          </div>
          <div className='flex flex-col p-10 items-start justify-between border-[#FF5637] border-solid border-2 rounded-lg hover:bg-[#FF5636] ease-in duration-700 cursor-pointer'>
            <h2 className='mb-20 font-bold text-2xl'>02</h2>
            <div>
              <h2 className='font-bold text-3xl mb-8'>
                Digital Transformation
              </h2>
              <p>
                If there's one thing we are good at, it's integrating technology
                into every facet of your business workflow. We help businesses
                break into the digital world, exposing them to countless
                opportunities and helping them evolve with digital evolution.
                And we're darn good at it!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-slate-300 h-auto py-6 md:py-32 md:px-12'>
        <div className='pl-8 md:pl-0'>
          <h2 className='font-bold text-4xl mb-10 text-center md:text-left md:mb-24 md:text-6xl'>
            Our Projects
          </h2>
        </div>
        <div className='my-8'>
          <Slide indicators={true}>
            <img src={Cheqloca} alt='fist' className='w-full' />
            <img src={Quess} alt='secn' className='w-full' />
          </Slide>
        </div>
      </div>
      <div className='bg-[#070707] h-auto py-12 px-12'>
        <div>
          <h2 className='font-bold text-4xl mb-20 md:mb-24 text-center text-white md:text-6xl'>
            Our Clients
          </h2>
        </div>
        <div className='flex items-center gap-10 justify-center flex-wrap my-8'>
          <img
            src={Hdit}
            alt='hdit'
            className='w-[100px] md:w-[140px] grayscale ease-out duration-500 hover:grayscale-0 cursor-pointer'
          />
          <img
            src={AAJ}
            alt='aaj'
            className='w-[100px] grayscale ease-out duration-500 hover:grayscale-0 cursor-pointer'
          />
        </div>
      </div>
      <div className='bg-[#0c1118] px-6 md:px-12 py-32 text-center text-white h-auto'>
        <h1 className='text-4xl md:text-6xl font-bold'>Contact Us</h1>
        <div className='flex justify-center mt-20 gap-10 flex-wrap items-center'>
          <div className='bg-[#090c11] w-96 flex items-center justify-between flex-col p-10 rounded-lg hover:shadow-[#ff55372f] shadow-lg '>
            <img src={Location} alt='loca' className='mb-10' />
            <p>Address</p>
            <p>
              <h1>Nervelabs</h1> is a people not a place
            </p>
          </div>
          <div className='bg-[#090c11] w-96 flex items-center justify-between flex-col p-10 rounded-lg hover:shadow-[#ff55372f] shadow-lg'>
            <img src={Email} alt='Email' className='mb-10' />
            <p>Email</p>
            <p>team@nervelabsafrica.com</p>
          </div>
          <div className='bg-[#090c11] w-96 flex items-center justify-between flex-col p-10 rounded-lg hover:shadow-[#ff55372f] shadow-lg'>
            <img src={Phone} alt='Phone' className='mb-10' />
            <p>Phone</p>
            <p>(+234)9168249033</p>
          </div>
        </div>
        <div className='flex-wrap md:flex-nowrap flex items-center w-full mt-20 justify-center gap-6'>
          <div className='bg-[#090c11] text-left w-full md:px-12 md:py-6 p-5 rounded-xl'>
            <div className='my-6'>
              <label for='name'>Name</label>
              <input
                type='text'
                name='Fullname'
                id='name'
                className='bg-[#fff1] md:mt-6 md:p-4 p-2 w-full rounded-md'
              />
            </div>
            <div className='my-6'>
              <label for='name'>Email</label>
              <input
                type='email'
                name='Emailaddress'
                id='email'
                className='bg-[#fff1] md:mt-6 p-2 md:p-4 w-full rounded-md'
              />
            </div>
            <div className='my-6'>
              <label for='name'>Message</label>
              <textarea
                name='message'
                id='mssg'
                cols='30'
                rows='10'
                className='bg-[#fff1] md:mt-6 md:p-4 p-2 w-full rounded-md'
              ></textarea>
            </div>
            <a
              href='mailto: team@nervelabsafrica.com'
              className='md:block bg-[#FF5637] text-white text-center md:px-12 md:py-6 px-6 py-3 rounded-xl hover:bg-[#c53218]'
            >
              Send
            </a>
          </div>
          <iframe
            title='map'
            className='h-96 w-full rounded-lg'
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.581102951369!2d3.36936691422531!3d6.574432024394426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b927a52975997%3A0xf9ee82e74b331fe5!2s3%20Adedotun%20Dina%20Cres%2C%20Mende%20105102%2C%20Lagos!5e0!3m2!1sen!2sng!4v1675749124521!5m2!1sen!2sng'
            allowfullscreen=''
            loading='lazy'
            referrerpolicy='no-referrer-when-downgrade'
          ></iframe>
        </div>
      </div>
      <div className='bg-black py-12 px-12 text-white'>
        <div className='flex-row text-center gap-4 md:flex justify-between py-6 border-b-2 border-[#ffffff10] items-center'>
          <img src={Logo} alt='logo' className='mb-6 md:mb-0' />
          <div className='flex flex-col gap-4 md:flex items-start justify-between'>
            <a
              href='mailto: team@nervelabsafrica.com'
              className='hover:text-[#FF5637]'
            >
              team@nervelabsafrica.com
            </a>
            <a href='tel: +2349168249033' className='hover:text-[#FF5637]'>
              +2349168249033
            </a>
          </div>
        </div>
        <div className='flex justify-end mt-6 items-center'>
          <p>© 2023 Nervelabs Innovative Africa Ltd. All Rights Reserved</p>
        </div>
      </div>
      <WhatsAppWidget
        phoneNo='+2348090303721'
        position='right'
        widgetWidth='300px'
        widgetWidthMobile='260px'
        autoOpen={true}
        autoOpenTimer={5000}
        messageBox={true}
        messageBoxTxt='Hi Nervlabs, is there any related service available ?'
        iconSize='40'
        iconColor='white'
        iconBgColor='tomato'
        headerIcon={Fav}
        headerIconColor='pink'
        headerTxtColor='black'
        headerBgColor='tomato'
        headerTitle='Aaron'
        headerCaption='Online'
        bodyBgColor='#bbb'
        chatPersonName='Support'
        chatMessage={
          <>
            Hi there 👋 <br />
            <br /> How can I help you?
          </>
        }
        footerBgColor='#999'
        btnBgColor='black'
        btnTxt='Start Chat'
        btnTxtColor='white'
      />
    </div>
  );
};

export default Home;
